import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    copy: String
  }

  copy(event) {
    navigator.clipboard.writeText(this.copyValue)
    if (event) {
      event.preventDefault()
    }
  }
}
