import FlatpickrController from 'controllers/flatpickr_controller'

export default class extends FlatpickrController {

  static targets = ['date', 'form', 'input']

  static classes = ['loadingDate']

  static values = {
    locale:         { type: String, default: 'en'    },
    closeLabel:     { type: String, default: 'Close' },
    headerTemplate: {
      type: String,
      default: `
        <div class="datepicker-header">
          <ul>
            <li class="close-datepicker" data-action="click->datepicker#toggleDatepicker">
              <a href="#">Close</a>
            </li>
          </ul>
        </div>
      `
    }
  }

  connect () {
    this.flatpickrOptions = {
      static:        true,
      clickOpens:    false,
      disableMobile: true,
      locale:        this.localeValue,
      appendTo:      this.element,
      dateFormat:    "Y-m-d",
      altInput:      true,
      altFormat:     "D d M",
      onChange:      [this.updateDate.bind(this), this.datePickerChanged.bind(this), this.submitForm.bind(this)],
      onOpen:        [this.datePickerOpened.bind(this)],
      onMonthChange: [this.datePickerMonthChanged.bind(this)],
      onYearChange:  [this.datePickerYearChanged.bind(this)],
      onReady:       [this.datePickerReady.bind(this)],
      onValueUpdate: [this.datePickerValueUpdated.bind(this)]
    }

    this.flatpickr = flatpickr(this.inputTarget, this.flatpickrOptions)

    this.prependDatepickerHeader()
  }

  toggleDatepicker (event) {
    if (event) {
      event.preventDefault()
    }

    if (this.flatpickr) {
      this.flatpickr.toggle()
    }
  }

  updateDate(selectedDates, dateAsString, flatpickr) {
    if (selectedDates.length > 0) {
      this.dateTarget.innerText = flatpickr.altInput.value;
      this.element.classList.add(this.loadingDateClass)
    }
  }

  submitForm (event) {
    if (this.formTarget) {
      this.formTarget.submit()
    }
  }

  prependDatepickerHeader () {
    if (this.flatpickr && this.flatpickr.calendarContainer) {
      this.flatpickr.calendarContainer.insertAdjacentHTML('afterbegin', this.headerTemplateValue)
    }
  }
}