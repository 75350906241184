import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'realtimeSettingChoice',
    'realtimeRejectSettingChoice',
    'openingTimes',
    'capacityInputs',
    'daysInput',
    'form'
  ]

  static classes = [
    'choices',
    'optionDescription',
    'choicesGroup',
    'dayMealSettings',
    'realtimeSettingDefault',
    'realtimeSettingOff',
    'realtimeSettingCapacity',
    'realtimeSettingAvailability',
    'realtimeRejectSettingAccept',
    'realtimeRejectSettingReject',
    'syncedOpeningHours',
    'manualOpeningHours'
  ]

  static outlets = ['backend--timeslots', 'backend--day']

  static values = {
    useRestaurantTimes:                 { type: Boolean,  default: false },
    useRestaurantSeats:                 { type: Boolean,  default: false },
    useRestaurantRealtimeSettings:      { type: Boolean,  default: false },
    useRestaurantReconfirmationSetting: { type: Boolean,  default: false }
  }

  connect () {
    this.realtimeSettingChoiceTargets.forEach(this.readRealtimeSetting.bind(this))
    this.realtimeRejectSettingChoiceTargets.forEach(this.readRealtimeRejectSetting.bind(this))
  }

  get realtimeSetting() {
    return this.realtimeSettingChoiceTargets.find(input => input.checked).value
  }

  renderDayLabels() {
    this.backendDayOutlets.forEach(day => day.renderSettings())
  }

  toggleTimeRestrictions() {
    this.useRestaurantRealtimeSettingsValue = !this.useRestaurantRealtimeSettingsValue
    this.renderDayLabels()
  }

  toggleTimeInputs() {
    this.useRestaurantTimesValue = !this.useRestaurantTimesValue
    this.renderDayLabels()
  }

  toggleSeatInputs() {
    this.useRestaurantSeatsValue = !this.useRestaurantSeatsValue
    this.renderDayLabels()
  }

  toggleReconfirmation() {
    this.useRestaurantReconfirmationSettingValue = !this.useRestaurantReconfirmationSettingValue
    this.renderDayLabels()
  }

  selectsToggle(isDisabled) {
    if(this.hasOpeningTimesTarget) {
      this.openingTimesTargets.forEach(openingTimes => {
        let selects = openingTimes.querySelectorAll('select')

        selects.forEach(select => { select.disabled = isDisabled })
      })
    }
  }

  readRealtimeSetting(input) {
    if (input.checked) {
      let setting = input.value

      this.formTarget.classList.remove(
        this.realtimeSettingDefaultClass,
        this.realtimeSettingOffClass,
        this.realtimeSettingCapacityClass,
        this.realtimeSettingAvailabilityClass
      )

      switch (setting) {
        case 'default':
          this.formTarget.classList.add(this.realtimeSettingDefaultClass)
          break

        case 'off':
          this.formTarget.classList.add(this.realtimeSettingOffClass)
          break

        case 'capacity':
          this.formTarget.classList.add(this.realtimeSettingCapacityClass)
          break

        case 'availability':
          this.formTarget.classList.add(this.realtimeSettingAvailabilityClass)
          break
      }

      this.renderDayLabels()
    }
  }

  readRealtimeRejectSetting(input) {
    if(input.checked) {
      let setting = input.value

      this.formTarget.classList.remove(
        this.realtimeRejectSettingRejectClass,
        this.realtimeRejectSettingAcceptClass
      )

      switch(setting) {
        case 'accept':
          this.formTarget.classList.add(this.realtimeRejectSettingAcceptClass)
          break

        case 'reject':
          this.formTarget.classList.add(this.realtimeRejectSettingRejectClass)
          break
      }

      // this.updateHeightOfChoiceGroups()
    }
  }

  // action realtime-setting input
  changeRealtimeSetting(event) {
    this.readRealtimeSetting(event.currentTarget)
  }
  // action realtime-reject-setting input
  changeRealtimeRejectSetting(event) {
    this.readRealtimeRejectSetting(event.currentTarget)
  }

  changeOpeningHoursForDaysInput(event) {
    let wrapper = this.daysInputTarget
    let checkbox = event.currentTarget

    if(checkbox.checked) {
      wrapper.classList.add(this.syncedOpeningHoursClass)
      wrapper.classList.remove(this.manualOpeningHoursClass)
      this.selectsToggle(true)
    }else {
      wrapper.classList.remove(this.syncedOpeningHoursClass)
      wrapper.classList.add(this.manualOpeningHoursClass)
      this.selectsToggle(false)
    }
  }

  updateHeightOfChoiceGroups() {
    let wideBreakpoint = 1239
    let input = this.choiceInputTargets.find(input => input.checked)
    let groups = this.formTarget.querySelectorAll('.choices-group')

    if(input != null && input.checked) {
      let choice = input.closest('.choice')
      let description = choice.querySelector('.option-description')
      let descrHeight = description.offsetHeight
      let lineHeight = parseInt(getComputedStyle(description).getPropertyValue('line-height'))

      if(window.innerWidth > wideBreakpoint) {
        groups.forEach(group => {
          group.style.paddingBottom = descrHeight + (lineHeight * 1.25) + 'px'
        })
      }
    }
  }
}
